import "./ZeroFeesBanner.css";


export default function ZeroFeeBanner() {

    return (
        <div className="card">
            <span>
                <a href="/tokensale">
                    Buy $EMMET
                </a>
            </span>
            <span>
                Get a staking fee discount!
            </span>
        </div>
    )
}